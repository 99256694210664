import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '@environments-candidat/environment';
import {
  ShowToastrService,
  SuiviProjet,
  SuiviProjetSyntheseView,
  SuiviProjetValidation,
  isAvanceInitiale,
  buildEtapeCleTitle,
} from '@shared-ui';

@Injectable()
export class SuiviProjetService {
  private readonly projetSuiviUrl = environment.apiSuiviUrl + 'suivi-projets';
  private readonly projetSubject = new ReplaySubject<SuiviProjet>(1);
  public projet$ = this.projetSubject.asObservable();

  constructor(private httpClient: HttpClient, private toastrService: ShowToastrService) {}

  getById(id: string): Observable<SuiviProjet> {
    return this.httpClient.get<SuiviProjet>(`${this.projetSuiviUrl}/${id}`).pipe(tap(p => this.projetSubject.next(p)));
  }

  getStatutValidation(id: string, idEC: string): Observable<SuiviProjetValidation> {
    return this.httpClient.get<SuiviProjetValidation>(`${this.projetSuiviUrl}/${id}/etapes-cle/${idEC}/statut-validation`);
  }

  addValidationAndReturn(id: string, idEC: string): Observable<SuiviProjetSyntheseView> {
    return this.projetSubject.pipe(
      switchMap(projet =>
        this.getStatutValidation(id, idEC).pipe(map(validation => this.mapProjetToEtapeCleView(projet, validation, idEC)))
      ),
      catchError(error => this.handleError(error))
    );
  }

  private mapProjetToEtapeCleView(projet: SuiviProjet, validation: SuiviProjetValidation, idEC: string): SuiviProjetSyntheseView {
    const currentEc = projet.etapesCle?.find(ec => ec.id === idEC);

    return {
      id: projet.id,
      acronyme: projet.acronyme,
      isValid: validation.valid,
      structures: projet.structures?.map(structure => ({
        ...structure,
        isValid: validation.structures[structure.id]?.valueOf() === true,
      })),
      title: 'Suivi - ' + buildEtapeCleTitle(currentEc, projet.etapesCle, '(dd/MM/yyyy)'),
      isAvanceInitiale: isAvanceInitiale(currentEc),
    };
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    this.toastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  sendSuiviProjetForStudy(id: string, idEC: string): Observable<SuiviProjet> {
    return this.httpClient.post<any>(environment.apiSuiviUrl + `suivi-projets/${id}/etapes-cle/${idEC}/envoie-etude`, {});
  }

  setSuiviProjetObservable(updatedProjet: SuiviProjet) {
    this.projetSubject.next(updatedProjet);
  }
}
